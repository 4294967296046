import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import Image from 'gatsby-image'

const FullwidthPageTemplate = ({ title, notice, content, contentComponent, asideImages }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  function AsideImages({asideImages}) {
    console.log(asideImages)
    if( !Array.isArray(asideImages) || !asideImages.length) {
      return (
        < ></ >
      )
    }

    return (
      <div className="column is-3 is-offset-1 vertical-justify">
        {asideImages.map(image => (
          < >
            <Image className="asideImage" fixed={image.image.childImageSharp.fixed} alt={image.image.alt} />
          </ >
        ))}
      </div>
    )
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-11 is-offset-1">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h2>
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <Notice notice={notice} />
            <PageContent className="content" content={content} />
          </div>
          <AsideImages asideImages={asideImages} />
        </div>
      </div>
    </section>
  )
}

FullwidthPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default FullwidthPageTemplate
